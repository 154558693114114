import React from "react";
import { INewsPosting } from "../NewsPosting";

import * as styles from "./styles.module.scss";
import "../../../styles/ckeditor.css";
import "../../../styles/customCkeditor.css";
import locationIcon from "../../../images/location.svg";

export type INewsPageView = {
  newspost: INewsPosting;
};

const PageView = ({ newspost }: INewsPageView) => {
  return (
    <div className={styles.container}>
      <h1>{newspost?.title}</h1>
      <h2>
        {newspost?.location && `${newspost?.location} | `}
        {newspost?.author && `${newspost?.author} `}
      </h2>
      <div className='ck-content' dangerouslySetInnerHTML={{ __html: newspost?.body! }}></div>
    </div>
  );
};

export default PageView;
