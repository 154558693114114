import * as React from "react";
import { useEffect, useState } from "react";
import { apiUrl } from "../../app.config";
import Layout from "../../components/layout/Layout";
import { INewsPosting } from "../../components/media/NewsPosting";
import ResponsiveContainer from "../../components/ui/ResponsiveContainer";
import NewsPageView from "../../components/media/PageView";

export type INewsPage = {
  id: string;
};

const OffersPage = (params: INewsPage) => {
  const postid: number = Number(params.id);
  const [loadedPost, setLoadedPost] = useState<INewsPosting>();

  useEffect(() => {
    fetch(`${apiUrl}/news/news-post-${postid}.json`)
      .then((r) => r.json())
      .then((newsposting: INewsPosting) => {
        setLoadedPost(newsposting);
      });
  }, []);

  return (
    <Layout>
      <ResponsiveContainer>
        <NewsPageView newspost={loadedPost!} />
      </ResponsiveContainer>
    </Layout>
  );
};

export default OffersPage;
